<template>
<svg xmlns="http://www.w3.org/2000/svg" style="transform: rotate(225deg)" class="ionicon" viewBox="0 0 512 512"><title>Arrow Up</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 244l144-144 144 144M256 120v292"/></svg>
</template>
<script>
export default{
    props:{
        fill:{
            type:String,
            default:'text-gray-400'
        }
    }
}
</script>