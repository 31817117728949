<template>
  <div class="px-4 relative">
        <ul role="list" class="mt-2 pb-4" v-if="actionsList.length>0">
          <li v-for="(item,key) in actionsList" :key="key" class="py-2">
              <Divider class="mt-2"><span class="font-normal">{{new Date(item.date).toDateString()}}</span></Divider>
              <ul role="list">
                   <li v-for="(action,key) in item.actions" :key="key" class="py-0.5 flex justify-between">
                     <div class="flex items-center">
                     <component v-if="action.type!='interest'" :is="selectIcon(action.type)" :fill="'#fff'"  class="w-8 h-8 p-1.5 text-white rounded-full flex justify-center items-center" 
                     :class="[action.type=='withdraw'?  'bg-gray-500' :  'bg-green-500']"/>
                     <div v-else class="w-8 h-8 p-2 text-white rounded-full flex justify-center items-center bg-green-500">%</div>
                     <span class="text-md font-semibold text-gray-700 ml-4 ">{{action.title}}</span>
                     <!-- <span v-if="action.ticket!=null" class="text-xs text-green-700 ml-2">(ticket {{action.ticket}})</span> -->
                     </div>
                     <span class="text-gray-700 text-xl" v-if="action.type=='reffer'">+ {{numberWithCommas(action.value)}}<span class="text-xs text-gray-500 ml-1">tickets</span></span>
                     <div v-else>
                       <p class="text-gray-400 text-xl" v-if="action.type=='withdraw'">- € 
                         {{euros(numberWithCommas(parseFloat(action.value).toFixed(2)))}}
                         <span class="text-sm -ml-1.5 text-gray-400">.{{cents(numberWithCommas(parseFloat(action.value).toFixed(2)))}}</span> 
                        </p>
                       <p class="text-gray-700 text-xl" v-else>+ € {{
                         euros(numberWithCommas(parseFloat(action.value).toFixed(2)))}}
                         <span class="text-sm -ml-1.5 text-gray-700">.{{cents(numberWithCommas(parseFloat(action.value).toFixed(2)))}}</span>
                      </p>
                      
                     </div>
                   </li>
              </ul>
          </li>
        </ul>
        <div class="mb-2 mt-4" v-else>
          <div>
          <p class="text-center text-gray-800 text-lg font-semibold">Nothing here yet...</p>
          <p class="text-center text-gray-500 mb-8">Deposit now to start using Savvy</p>
          </div>
         <div class="mt-2 py-4 px-2 grid grid-flow-col gap-1 w-full justify-between items-center auto-cols-max border border-gray-200 rounded-xl rounded text-center">
          <DepositIcon class="w-10 h-10 p-2 bg-green-500 rounded-full text-white" :fill="'#3697A0'"/>
          <p class="text-gray-500 text-left text-xs ml-1 w-36">Save and get more tickets into future draws</p>
          <button @click="this.$router.push('deposit')" type="button" class="w-24 px-2 inline-flex inline-flex py-2.5 justify-center m-auto items-center border border-transparent shadow-sm text-xs font-medium rounded-xl text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Deposit now
          </button>
        </div>
        <div class="mt-2 py-4 px-2 grid grid-flow-col gap-1 w-full justify-between items-center auto-cols-max border border-gray-200 rounded-xl rounded text-center">
            <PersonAddIcon class="w-10 h-10 p-2 bg-green-500 rounded-full text-white " :fill="'#3697A0'"/>
          <span class="text-gray-500 text-left text-xs ml-1 w-36">Improve your odds by inviting more friends</span>
          <button @click="this.$router.push('invite')" type="button" class="w-24  px-2 inline-flex  py-2.5 justify-center m-auto items-center border border-transparent shadow-sm text-xs font-medium rounded-xl text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">            
            Invite a friend
          </button>
        </div>
        </div>
  </div>
</template>

<script>
import Divider from '@/components/divider.vue'
import WalletIcon from '@/assets/icons/wallet-outline.vue'
import TicketIcon from '@/assets/icons/ticket.vue'
import DepositIcon from '@/assets/icons/deposit.vue'
import WithdrawIcon from '@/assets/icons/withdraw.vue'
import StarIcon from '@/assets/icons/star-outline.vue'
import InterestIcon from '@/assets/icons/interest-outline.vue'
import PersonAddIcon from '@/assets/icons/people.vue'


export default  {
  components:{
    Divider,
    WalletIcon,
    TicketIcon,
    PersonAddIcon,
    DepositIcon
  },
  computed:{
    actionsList(){
      return this.$store.state.accountActionsList
    },
       balance(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.balance).toFixed(2))
        },
        tickets(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.tickets))
        },
  },
  methods:{
    addItemToActionList(){
      this.$store.commit('addItemToActionList', {
        deposit:this.depositAmount,
        tickets: Math.floor(this.depositAmount/2)
      })
    },
    selectIcon(type){
      switch (type){
        case 'deposit':
          return DepositIcon

        case 'withdraw':
          return WithdrawIcon

        case 'prize':
          return StarIcon

        case 'interest':
          return InterestIcon

        case 'reffer':
          return PersonAddIcon

        case 'sorder':
          return DepositIcon

      }
    },
    euros(balance){
          return balance.slice(0,-3)
        },
    cents(balance){
          return balance.slice(balance.length-2,balance.length)
        },
  }
}
</script>